// extracted by mini-css-extract-plugin
export var cardwrapper = "createlist-module--cardwrapper--VTfox";
export var cardwrappersp = "createlist-module--cardwrappersp--EGjIe";
export var centerimgwrapper = "createlist-module--centerimgwrapper--DwLMW";
export var contentswrapper = "createlist-module--contentswrapper--jUSHV";
export var date = "createlist-module--date--x7E+5";
export var description = "createlist-module--description--i0Z83";
export var descriptionwrapper = "createlist-module--descriptionwrapper--5x++z";
export var img = "createlist-module--img--5ymzY";
export var link = "createlist-module--link--7nGRI";
export var overcolor = "createlist-module--overcolor--LLvA8";
export var stackdatewrapper = "createlist-module--stackdatewrapper--A7B6R";
export var stackwrapper = "createlist-module--stackwrapper--ChWM3";
export var thumbnailwrapper = "createlist-module--thumbnailwrapper--KPUUI";
export var title = "createlist-module--title--oPPZ6";
import React from 'react'
import { Link } from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { kebabCase } from 'lodash'

import * as styles from '../styles/createlist.module.scss'
import { createLinkStyle } from './functions/createconst'
import useWindowWidth from './functions/useWindowWidth'


const MakeCard = ({post, slug}) =>{
    const thumbnail = getImage(post.frontmatter.hero)
    const LinkStyle = createLinkStyle()
    let description

    const width = useWindowWidth()
    

    if(width < 800){
        if(post.frontmatter.description.length < 80){
            description = post.frontmatter.description;
        }else{
            description = post.frontmatter.description.substr(0, 80) + "…";
        }
    }else{
        description = post.frontmatter.description;
    }


    return(
        <div className = {width < 800 ? styles.cardwrappersp : styles.cardwrapper}>
            <Link to = {slug} className={styles.link} style={LinkStyle} >
                <div className = {styles.stackwrapper}>
                        <div className = {styles.contentswrapper}>
                            
                            <div className = {styles.thumbnailwrapper}>
                                <div className = {styles.centerimgwrapper}>
                                    <div className = {styles.overcolor}></div>
                                    <GatsbyImage
                                        image = {thumbnail}
                                        alt = "thumbnail img"
                                        className = {styles.img}
                                    />
                                </div>
                            </div>

                            <div className={styles.title}>{post.frontmatter.title}</div>
                            <div className={styles.descriptionwrapper}>
                                <div className={styles.description}>{description}</div>
                            </div>
                            <div className={styles.stackdatewrapper}>
                                <div className={styles.date}>{post.frontmatter.date}</div>
                            </div>
                        </div>
                </div>
            </Link>
        </div>
    )
}

const CreateList = ({posts}) =>{
    let items = []

    for(let i = 0; i < posts.length; i++){
        let slug = `/${kebabCase(posts[i].frontmatter.category1)}/${kebabCase(posts[i].frontmatter.category2)}${posts[i].fields.slug}`
        let post = posts[i]

        items.push(
            <div className = {styles.contentscardwrapper} key={post}>
                <MakeCard post = {post} slug = {slug}></MakeCard>
            </div>    
        )
    }

    return(
        <div>
            {items}
        </div>
    )
}

export default CreateList
import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import Layout from "../components/layout"

import * as styles from "../styles/pagenation.module.scss"
import CategoryList from "../components/categorylist"
import { createCategoryNameMap } from "../components/functions/createconst"
import CreateList from "../components/createlist"

const PageNation = ({ pageContext, data, location, }) => {


    const categorynamemap = createCategoryNameMap()

    const category = pageContext.category
    const allposts = data.Allposts.nodes
    const siteTitle = data.site.siteMetadata.title
    const categoryHeader = `全ての投稿`
    const categorygroup = data.categorygroup.group
    const totalCount = data.Allposts.totalCount
    const page = pageContext.page;
    const num = pageContext.num;
    const allpage = pageContext.allpage;
    const thispageposts = [];
    const thispagenationlink = [];

    for(let i = 0; i < num; i++){
        if(allposts[page * num + i]){
            thispageposts.push(allposts[page * num + i])
        }
        else{
            break
        }
    }


    for(let i = 0; i < allpage; i++){
        if(i == page){
            thispagenationlink.push(
                <div className={styles.thispage}> {i + 1} </div>
            )
        }
        else{
            thispagenationlink.push(
                <div className={styles.otherpagelink}>
                    <a href={"page" + i}>{i+1}</a>
                </div>
            )
        }
    }


    return (
        <Layout location = {location} title = {siteTitle}>
            <div className={styles.bodywrapper}>
                <h1 className = {styles.categorytitle}>{categoryHeader}</h1>

                <CategoryList categorygroup={categorygroup}></CategoryList>

                <div>
                    {totalCount}件の投稿中、{page * 10 + 1}件目から{page * 10 + thispageposts.length }件目を表示中
                </div>

                <CreateList posts = {thispageposts}></CreateList>

                <div className={styles.flexwrapper}>
                    {thispagenationlink}
                </div>

            </div>
        </Layout>
    )
}

PageNation.propTypes = {
    pageContext: PropTypes.shape({
        
        category: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
    allMdx: PropTypes.shape({
        totalCount: PropTypes.number.isRequired,
        edges: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
                slug: PropTypes.string.isRequired,
            }),
            }),
        }).isRequired
        ),
    }),
    }),
}

export default PageNation

export const pageQuery = graphql`
query {
    site{
        siteMetadata{
            title
        }
    }

    categorygroup: allMdx(limit: 2000) {
        group(field: frontmatter___category2) {
            fieldValue
            totalCount
        }
    }

    Allposts: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {frontmatter: {pagetype: {eq: "blog"}}}
    ) {
        nodes {
            id
            fields {
                slug
            }
            frontmatter {
                category2
                category1
                date(formatString: "MMMM DD, YYYY")
                description
                title
                tags
                hero{
                    childImageSharp{
                        gatsbyImageData(
                            width: 600
                            placeholder: NONE
                        )
                    }
                }
            }
        }
        totalCount
    }
}
`
import React from 'react'
import { motion } from "framer-motion"
import { StaticImage} from "gatsby-plugin-image"
import { useLocation } from "@reach/router"

import * as styles from '../styles/categorylist.module.scss'
import { createLinkStyle } from './functions/createconst'
import useWindowWidth from './functions/useWindowWidth'


function MakeCategoryList ({categorygroup}){
    const location = useLocation()

    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath

    const LinkStyle = createLinkStyle()



    const categoryHover = {
        rest:{
        },
        hover:{
            scale: 1.2,
            y: "-5px",
        },    
    }

    const notHover = {
        rest:{
        },
        hover:{
        },   
    }

    const width = useWindowWidth()
    

    return (
        <div className = { isRootPath ? styles.rootstyle : styles.notrootstyle}>
            <div className={styles.categorywrapper}>

                <div className = {styles.stackimgwrapper}>
                    <div className = {styles.stackareawrapper}>
                        <div className = {styles.areastack}>
                            <div className = {styles.areawrapper}>
                                    <a href = "/oita/oita-city/" style = {LinkStyle} className = {styles.oitalink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >大分</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/beppu-city/" style = {LinkStyle} className = {styles.beppulink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >別府</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/nakatsu-usa-city" style = {LinkStyle} className = {styles.nakatsuusalink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >中津・宇佐</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/bungotakada-kunisaki-city" style = {LinkStyle} className = {styles.bungotakadakunisakilink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >豊後高田・国東</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/kitsuki-hiji-city" style = {LinkStyle} className = {styles.kitsukihijilink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >杵築・日出</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/hita-city" style = {LinkStyle} className = {styles.hitalink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >日田</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/yufu-city" style = {LinkStyle} className = {styles.yufulink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >湯布院</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/kusu-kokonoe-town" style = {LinkStyle} className = {styles.kusukokonoelink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >玖珠・九重</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/taketa-bungooono-city" style = {LinkStyle} className = {styles.taketabungooonolink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >竹田・豊後大野</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/usuki-tukumi-city" style = {LinkStyle} className = {styles.usukitukumilink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >臼杵・津久見</motion.div>
                                        </div>
                                    </a>
                                    <a href = "/oita/saiki-city" style = {LinkStyle} className = {styles.saikilink}>
                                        <div className = {styles.marker}>
                                            <motion.div className = {styles.city}
                                                whileHover = "hover"
                                                variants= {width > 1023 & isRootPath ? categoryHover : notHover}
                                            >佐伯</motion.div>
                                        </div>
                                    </a>
                            </div>
                        </div>
                        <StaticImage className = {styles.oitamap}
                            src = "../images/toppage/oitamap.png"
                            alt = "separate img"
                            layout = 'fixed'
                            placeholder="none"
                            width={600}
                        >
                        </StaticImage>
                    </div>
                </div>
            </div>

            <div className={styles.otherprefecture}>
                <div className={styles.prefecturewrapper}>
                    <a href="/kumamoto" className = {styles.prefecturelink}>
                        <div className={styles.marker}>
                            熊本県
                        </div>
                    </a>
                </div>

                <div className={styles.cityflexwrapper}>

                    <div className={styles.citylinkwrapper}>
                        <a href = "/kumamoto/aso-gun" style = {LinkStyle} className = {styles.citylink}>
                            <div className = {styles.marker}>
                                <div className = {styles.city}>
                                    阿蘇
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}


const CategoryList = ({categorygroup})=>{
    return(
        <MakeCategoryList
            categorygroup = {categorygroup}
        ></MakeCategoryList>
    )
}

export default CategoryList